* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }
  
  :root {
    --purple: hsl(240, 80%, 89%);
    --pink: hsl(0, 59%, 94%);
    --light-bg: hsl(204, 37%, 92%);
    --light-gray-bg: hsl(0, 0%, 94%);
    --white: hsl(0, 0%, 100%);
    --dark: hsl(0, 0%, 7%);
    --text-gray: hsl(0, 0%, 30%);
  }
  
  .Training {
    background: var(--light-bg);
    font-family: "Space Grotesk", sans-serif;
    color: var(--dark);
    text-align: center;
  }
  
  .Training h3 {
    font-size: 1.5em;
    font-weight: 700;
  }
  
  .Training p {
    font-size: 1em;
    line-height: 1.7;
    font-weight: 300;
    color: var(--text-gray);
    
  }
  
  .description {
    white-space: wrap;
  }
  
  a {
    text-decoration: none;
    color: inherit;
  }
  
  .Trainingwrap {
    /* display: flex;
    justify: space-between; */
    align-items: stretch;
    width: 100%;
    gap: 24px;
    padding: 24px;
    flex-wrap: wrap;
  }
  
  .Trainingbox {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    position: relative;
    padding: 24px;
    background: #fff;
  }
  
  .Trainingbox-top {
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 12px;
    margin-bottom: 15px;
  }
  
  .Trainingbox-image {
    width: 100%;
    height: 360px;
    object-fit: cover;
    object-position: 50% 20%;
  }
  
  .Trainingtitle-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .Trainingbox-title {
    border-left: 3px solid var(--purple);
    padding-left: 12px;
  }
  
  .Traininguser-follow-info {
    color: hsl(0, 0%, 60%);
   
  }
  
  .Trainingbutton {
    display: block;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: auto;
    padding: 16px;
    color: #000;
    background: transparent;
    box-shadow: 0px 0px 0px 1px black inset;
    transition: background 0.4s ease;
  }
  
  .Trainingbutton:hover {
    background: var(--purple);
  }
  
  .fill-one {
    background: var(--light-bg);
  }
  
  .fill-two {
    background: var(--pink);
  }
  
  /* RESPONSIVE QUERIES */
  
  @media (min-width: 320px) {
    .Trainingtitle-flex {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: start;
    }
    .Traininguser-follow-info {
      margin-top: 6px;
    }
  }
  
  @media (min-width: 460px) {
    .Trainingtitle-flex {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: start;
    }
    .Traininguser-follow-info {
      margin-top: 6px;
    }
  }
  
  @media (min-width: 640px) {
    .TrainingTrainingbox {
      flex-basis: calc(50% - 12px);
    }
    .Trainingtitle-flex {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: start;
    }
    .Traininguser-follow-info {
      margin-top: 6px;
    }
  }
  
  @media (min-width: 840px) {
    .Trainingtitle-flex {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: start;
    }
    .Traininguser-follow-info {
      margin-top: 6px;
    }
  }
  
  @media (min-width: 1024px) {
    .TrainingTrainingbox {
      flex-basis: calc(33.3% - 16px);
    }
    .Trainingtitle-flex {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: start;
    }
    .Traininguser-follow-info {
      margin-top: 6px;
    }
  }
  
  @media (min-width: 1100px) {
    .TrainingTrainingbox {
      flex-basis: calc(25% - 18px);
    }
  }
  