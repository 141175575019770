.custom-input {
  border: 1px solid rgb(99, 94, 94);
  height: 40px;
  border-radius: 9px;
}

.custom-input1 {
  border: 1px solid rgb(99, 94, 94);
  width: 62%;
  border-radius: 6px;
  margin-top: 10px;
}

.custom-input2 {
  width: 60%;
  border-radius: 8px;
}

.offcanvas-body {
  padding: 0px;
}

.offcanvas_li {
  font-size: larger;
}

.offcanvas_li:hover {
  background-color: #0b5ed74d;
  color: white;
}
